import React from "react";
import "./App.css";
import "./index.css";
import logo from "../src/images/Lisa-removebg-preview.png";
import lisa from "../src/images/smile.png";
import lisa_con from "../src/images/smilr.png";
import comp from "../src/images/cpmp.png";
import git from "../src/images/git.png";
import react_logo from "../src/images/react_logo.png";
import flutter_logo from "../src/images/flutter.png";
import html_logo from "../src/images/html.png";
import css_logo from "../src/images/css.png";
import js_logo from "../src/images/javascript.png";
import sql_logo from "../src/images/mysql.png";
import php_logo from "../src/images/php.png";
import firebase_logo from "../src/images/firebase.png";
import figma_logo from "../src/images/figma.png";
import java_logo from "../src/images/java.png";
import python_logo from "../src/images/python.png";
import canva_logo from "../src/images/canva_logo.png";
import hire from "../src/images/Group 8.png";
import tiktok from "../src/images/TikTok.png";
import email from "../src/images/Circled Envelope.png";
import instagram from "../src/images/Instagram.png";
import phone from "../src/images/Phone.png";
import linkedin from "../src/images/LinkedIn Circled.png";
import django from "../src/images/django.png";
import tailwind from "../src/images/tailwind.png";
import phonehold from "../src/images/phoneholder.jpg";
import laptop from "../src/images/laptops.jpg";
import CV from "../src/Aileen_Akpalu_CV.pdf";
import { Navbar } from "flowbite-react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
function App() {
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("Aileen_Akpalu_CV.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Aileen_Akpalu_CV.pdf";
        alink.click();
      });
    });
  };
  return (
    <div class="mx-2">
      <section id="home" class="xl:h-screen h-auto">
        {/* main page */}
        <Navbar fluid={true} rounded={true} class="w-auto">
          <Navbar.Brand href="#">
            <img src={logo} className="md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Navbar.Link
              class=" block py-2 pr-4 pl-3 text-cutepink rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              href="#home"
            >
              Home
            </Navbar.Link>
            <Navbar.Link
              class="block py-2 pr-4 pl-3 text-cutepink rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent "
              href="#education"
            >
              Education
            </Navbar.Link>
            <Navbar.Link
              class="block py-2 pr-4 pl-3 text-cutepink rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent "
              href="#about"
            >
              Skills
            </Navbar.Link>
            <Navbar.Link
              class="block py-2 pr-4 pl-3 text-cutepink  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              href="#projects"
            >
              Projects
            </Navbar.Link>
            <Navbar.Link
              class="block py-2 pr-4 pl-3 text-cutepink  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              href="#contact"
            >
              Contact
            </Navbar.Link>
          </Navbar.Collapse>
        </Navbar>

        <h1 class="mb-4 mt-8 text-4xl font-extrabold tracking-tight leading-none text-cutepink md:text-5xl lg:text-6xl dark:text-cutepink text-center">
          Hi, I am Aileen Akpalu
        </h1>
        <p class="text-white text-center xl:text-xl font-bold">
          Software Engineer and Content creator
        </p>
        <div class=" mx-auto container grid grid-cols-3 flex justify-items-center md:pt-20 pt-10 gap-3">
          <div class="md:col-span-1 col-span-3 text-center md:pt-28">
            <h1 class="text-3xl font-bold md:text-4xl lg:text-5xl text-white">
              Designer
            </h1>
            <p class="text-white">
              UI/UX Designer with passion for creating beautiful user
              experiences
            </p>
          </div>
          <div class=" md:col-span-1 col-span-3 blob w-full flex justify-center bg-cutepink dark:bg-cutepink">
            <img src={lisa} className="mx-3 w-72 " alt="Lisa Logo" />
          </div>
          <div class=" md:col-span-1 col-span-3 text-center md:pt-28">
            <h1 class="text-3xl font-bold md:text-4xl lg:text-5xl text-white">
              {"<"}Coder{">"}
            </h1>
            <p class="text-white">
              Full Stack Developer who focuses on clean and efficient code{" "}
            </p>
          </div>
        </div>
      </section>
      <section id="education" class="xl:h-screen h-auto mx-2 text-center">
        <Navbar fluid={true} rounded={true} class="bg-transparent">
          <Navbar.Brand href="#">
            <img src={logo} className="mr-3 md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
        </Navbar>
        <div>
          <p class="mb-4 tracking-tight leading-none text-cutepink text-xs dark:text-cutepink">
            educational background
          </p>
          <h1 class="mb-4 md:text-6xl text-5xl font-bold tracking-tight leading-none text-cutepink dark:text-cutepink">
            Education
          </h1>

          <div class="mt-8 text-justify">
            <div class="flex justify-center items-center">
              <div class="bg-gray-400 rounded-full h-10 w-10 border-none ml-4"></div>
            </div>
            <div class="grid grid-cols-12 divide-x-2 divide-cutepink gap-4 xl:h-48 mb-1 mt-1">
              <div class="col-span-6 justify-self-end">
                <p class="font-normal text-gray-500">Nov 2015-May 2018</p>
              </div>
              <div class="col-span-6 ">
                <p class="font-bold text-white xl:text-2xl text-xl ml-2">
                  Achimota School
                </p>
                <p class="font-normal text-white xl:text-lg text-[15px] ml-2">
                  General Science,WASSCE
                </p>
                <ul class="font-light text-white text-sm ml-3 list-inside list-disc text-justify">
                  <li>
                    {" "}
                    Basketball Team: Competed in Accra InterSchools, securing an
                    impressive 2nd place.
                  </li>
                  <li>
                    {" "}
                    Achimota Cadets: Honored to serve as Platoon Leader for my
                    house during my final year.
                  </li>
                  <li>
                    {" "}
                    American Field Service Club: Actively contributed and
                    selected as the publicity coordinator in my final year.
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <div class="bg-gray-400 rounded-full h-10 w-10 border-none ml-4"></div>
            </div>
            <div class="grid grid-cols-12 divide-x-2 divide-cutepink gap-4 xl:h-48 mt-1 mb-1">
              <div class="col-span-6 justify-self-end text-xl text-white text-left">
                <p class="font-bold text-white xl:text-2xl text-xl">
                  Ashesi University
                </p>
                <p class="font-normal text-white xl:text-lg text-[15px]">
                  Computer Science,Bsc
                </p>
                <ul class="font-light text-white text-sm list-inside list-disc text-justify">
                  <li>
                    {" "}
                    Math Tutor: Mentored first-year students in Calculus during
                    my second year.
                  </li>
                  <li>
                    {" "}
                    Berekuso Ashesi Leo Club: Served as Secretary and later Vice
                    President.
                  </li>
                  <li>
                    {" "}
                    Football Player: Active in Red Army Women's team, captained
                    in third year, and became coach in final year. Led the team
                    to victory in Ashesi Women's Premier League and maiden
                    Ashesi Women's Champions League.{" "}
                  </li>
                </ul>
              </div>
              <div class="col-span-6">
                <p class="font-normal text-gray-500 ml-2">Sep 2018-May 2022</p>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <div class="bg-gray-400 rounded-full h-10 w-10 border-none ml-4"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="w-auto xl:h-screen h-auto">
        <Navbar fluid={true} rounded={true} class="bg-transparent">
          <Navbar.Brand href="#">
            <img src={logo} className="mr-3 md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
        </Navbar>
        <div class="mx-auto container grid grid-cols-12 flex justify-items-center gap-4">
          <div class="col-span-12 md:col-span-6 md:text-justify text-center">
            <img
              src={comp}
              alt="comp"
              className=" md:w-88 w-56 md:-ml-6 ml-20"
            ></img>
            <h1 class="md:text-6xl text-3xl font-bold text-cutepink  md:text-left md:block hidden">
              Skills{"&"}
              <br></br>Experience
            </h1>
            <h1 class="md:text-6xl text-3xl font-bold text-cutepink  md:text-left md:hidden block text-center">
              Skills{"&"}Experience
            </h1>
            <p class="md:leading-7 leading-normal text-white pt-2 md:text-lg text-xs">
              The scope of my work is largely focused on frontend development
              for both web and mobile development.<br></br>Backend development
              is focused on PHP,MySQL,Firebase or Django. <br></br>
              For my content creation, I use CapCut and Canva to edit my videos.
              I have been creating food,travel and lifestyle content since 2021
              and have over 14000+ followers on Tiktok and Instagram.
            </p>
          </div>
          <div class="flex justify-center col-span-12 md:col-span-6 md:pr-4 pr-10 md:pt-1 pt-2">
            <div class="grid grid-cols-11 ">
              <div class="col-start-6 col-span-4 ...">
                <img
                  src={git}
                  alt="Git logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-4 col-span-3 ...">
                <img
                  src={react_logo}
                  alt="React logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-8 col-span-3 ...">
                <img
                  src={flutter_logo}
                  alt="Flutter logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-2 col-span-3 ...">
                <img
                  src={html_logo}
                  alt="HTML logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-6 col-span-3 ...">
                <img
                  src={js_logo}
                  alt="CSS logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-10 col-span-3 ...">
                <img
                  src={css_logo}
                  alt="JS logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-4 col-span-3 ...">
                <img
                  src={django}
                  alt="Firebase logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-8 col-span-3 ...">
                <img
                  src={php_logo}
                  alt="PHP logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-2 col-span-3 ...">
                <img
                  src={firebase_logo}
                  alt="SQL logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-6 col-span-3 ...">
                <img
                  src={python_logo}
                  alt="Python logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-10 col-span-3 ...">
                <img
                  src={sql_logo}
                  alt="Djanho logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-4 col-span-3 ...">
                <img
                  src={figma_logo}
                  alt="Figma logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-8 col-span-3 ...">
                <img
                  src={canva_logo}
                  alt="Canva logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-6 col-span-4 ...">
                <img
                  src={tailwind}
                  alt="Tailwind logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="projects" class="xl:h-screen h-auto mx-2 text-center">
        <Navbar fluid={true} rounded={true} class="bg-transparent">
          <Navbar.Brand href="#">
            <img src={logo} className="mr-3 md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
        </Navbar>
        <div>
          <p class="mb-4 tracking-tight leading-none text-cutepink text-xs dark:text-cutepink">
            some of my recent works
          </p>
          <h1 class="mb-4 md:text-6xl text-5xl font-bold tracking-tight leading-none text-cutepink dark:text-cutepink">
            Projects
          </h1>

          <div className="container mx-auto pr-4">
            <div className="flex items-center justify-center w-full h-full md:py-24 py-4 px-4">
              {/* Carousel for desktop and large size devices */}
              <CarouselProvider
                className="lg:block hidden"
                naturalSlideWidth={100}
                isIntrinsicHeight={true}
                totalSlides={5}
                visibleSlides={4}
                step={1}
                infinite={true}
              >
                <div className="w-full relative flex items-center justify-center">
                  <ButtonBack
                    role="button"
                    aria-label="slide backward"
                    className="w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-pink-100 absolute z-30 left-0 ml-8 focus:outline-none focus:bg-pink-100 focus:ring-2 focus:ring-offset-2 focus:ring-cutepink cursor-pointer"
                    id="prev"
                  >
                    <svg
                      width={8}
                      height={14}
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 1L1 7L7 13"
                        stroke="pink"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ButtonBack>
                  <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                    <Slider>
                      <div
                        id="slider"
                        className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700"
                      >
                        <Slide index={0}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={phonehold}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://www.figma.com/file/67QHm7dPmi5rXLgDuD2Lj8/Lamber?type=design&node-id=0%3A1&mode=design&t=iIMQ8tdALPtg8wPO-1"
                                target="_blank"
                              >
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Lamped
                                </h2>
                              </a>

                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A mobile application for reminding people to
                                  take medications
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={1}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={laptop}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://github.com/melwinkle/capstone"
                                target="_blank"
                              >
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Lamber
                                </h2>
                              </a>

                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A mobile and web application for handling
                                  emergency response
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={2}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={laptop}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://github.com/melwinkle/eventsmanagements"
                                target="_blank"
                              >
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Events Management
                                </h2>
                              </a>

                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A web application to create and manage events
                                  for event organizers. Customers can also buy
                                  tickets for events
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={3}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={laptop}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://github.com/melwinkle/businessmanagements"
                                target="_blank"
                              >
                                {" "}
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Business Management
                                </h2>
                              </a>
                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A web application for store owners to manage
                                  and track inventory, employees and stock
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={4}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={phonehold}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://www.figma.com/file/FHIZO5poEq9m1TZFAxUTmb/Alarm_Clock?type=design&node-id=0%3A1&mode=design&t=C9mfwBq2xzhqNpZo-1"
                                target="_blank"
                              ></a>
                              <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                Alarm Clock
                              </h2>
                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A mobile application design for alarm clock.
                                  Wireframes available
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                      </div>
                    </Slider>
                  </div>
                  <ButtonNext
                    role="button"
                    aria-label="slide forward"
                    className="w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-pink-100 absolute z-30 right-0 mr-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cutepink"
                    id="next"
                  >
                    <svg
                      width={8}
                      height={14}
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L7 7L1 13"
                        stroke="pink"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ButtonNext>
                </div>
              </CarouselProvider>

              {/* Carousel for tablet and medium size devices */}
              <CarouselProvider
                className="lg:hidden md:block hidden"
                naturalSlideWidth={100}
                isIntrinsicHeight={true}
                totalSlides={5}
                visibleSlides={2}
                step={1}
                infinite={true}
              >
                <div className="w-full relative flex items-center justify-center">
                  <ButtonBack
                    role="button"
                    aria-label="slide backward"
                    className="w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-pink-100 absolute z-30 left-0 ml-8 focus:outline-none focus:bg-pink-100 focus:ring-2 focus:ring-offset-2 focus:ring-cutepink cursor-pointer"
                    id="prev"
                  >
                    <svg
                      width={8}
                      height={14}
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 1L1 7L7 13"
                        stroke="pink"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ButtonBack>
                  <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                    <Slider>
                      <div
                        id="slider"
                        className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700"
                      >
                        <Slide index={0}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={phonehold}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://www.figma.com/file/67QHm7dPmi5rXLgDuD2Lj8/Lamber?type=design&node-id=0%3A1&mode=design&t=iIMQ8tdALPtg8wPO-1"
                                target="_blank"
                              >
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Lamped
                                </h2>
                              </a>

                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A mobile application for reminding people to
                                  take medications
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={1}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={laptop}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://github.com/melwinkle/capstone"
                                target="_blank"
                              >
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Lamber
                                </h2>
                              </a>

                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A mobile and web application for handling
                                  emergency response
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={2}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={laptop}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://github.com/melwinkle/eventsmanagements"
                                target="_blank"
                              >
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Events Management
                                </h2>
                              </a>

                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A web application to create and manage events
                                  for event organizers. Customers can also buy
                                  tickets for events
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={3}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={laptop}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://github.com/melwinkle/businessmanagements"
                                target="_blank"
                              >
                                {" "}
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Business Management
                                </h2>
                              </a>
                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A web application for store owners to manage
                                  and track inventory, employees and stock
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={4}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={phonehold}
                              alt="sitting area"
                              className="object-cover object-center w-full h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://www.figma.com/file/FHIZO5poEq9m1TZFAxUTmb/Alarm_Clock?type=design&node-id=0%3A1&mode=design&t=C9mfwBq2xzhqNpZo-1"
                                target="_blank"
                              ></a>
                              <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                Alarm Clock
                              </h2>
                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A mobile application design for alarm clock.
                                  Wireframes available
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                      </div>
                    </Slider>
                  </div>
                  <ButtonNext
                    role="button"
                    aria-label="slide forward"
                    className="w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-pink-100 absolute z-30 right-0 mr-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cutepink"
                    id="next"
                  >
                    <svg
                      width={8}
                      height={14}
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L7 7L1 13"
                        stroke="pink"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ButtonNext>
                </div>
              </CarouselProvider>

              {/* Carousel for mobile and Small size Devices */}
              <CarouselProvider
                className="block md:hidden justify-center "
                naturalSlideWidth={50}
                isIntrinsicHeight={true}
                totalSlides={5}
                visibleSlides={1}
                step={1}
                infinite={true}
              >
                <div className="w-full relative flex items-center justify-center">
                  <ButtonBack
                    role="button"
                    aria-label="slide backward"
                    className="w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-pink-100 absolute z-30 left-0 ml-8 focus:outline-none focus:bg-pink-100 focus:ring-2 focus:ring-offset-2 focus:ring-cutepink cursor-pointer"
                    id="prev"
                  >
                    <svg
                      width={8}
                      height={14}
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 1L1 7L7 13"
                        stroke="pink"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ButtonBack>
                  <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                    <Slider>
                      <div
                        id="slider"
                        className="h-full flex lg:gap-8 md:gap-6 gap-2 items-center justify-center transition ease-out duration-700 ml-2"
                      >
                        <Slide index={0}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={phonehold}
                              alt="sitting area"
                              className="object-cover object-center w-[28rem] h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://www.figma.com/file/67QHm7dPmi5rXLgDuD2Lj8/Lamber?type=design&node-id=0%3A1&mode=design&t=iIMQ8tdALPtg8wPO-1"
                                target="_blank"
                              >
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Lamped
                                </h2>
                              </a>

                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A mobile application for reminding people to
                                  take medications
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={1}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={laptop}
                              alt="sitting area"
                              className="object-cover object-center w-[28rem] h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://github.com/melwinkle/capstone"
                                target="_blank"
                              >
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Lamber
                                </h2>
                              </a>

                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A mobile and web application for handling
                                  emergency response
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={2}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={laptop}
                              alt="sitting area"
                              className="object-cover object-center w-[28rem] h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://github.com/melwinkle/eventsmanagements"
                                target="_blank"
                              >
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Events Management
                                </h2>
                              </a>

                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A web application to create and manage events
                                  for event organizers. Customers can also buy
                                  tickets for events
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={3}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={laptop}
                              alt="sitting area"
                              className="object-cover object-center w-[28rem] h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://github.com/melwinkle/businessmanagements"
                                target="_blank"
                              >
                                {" "}
                                <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                  Business Management
                                </h2>
                              </a>
                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A web application for store owners to manage
                                  and track inventory, employees and stock
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                        <Slide index={4}>
                          <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img
                              src={phonehold}
                              alt="sitting area"
                              className="object-cover object-center w-[28rem] h-[29rem]"
                            />
                            <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                              <a
                                href="https://www.figma.com/file/FHIZO5poEq9m1TZFAxUTmb/Alarm_Clock?type=design&node-id=0%3A1&mode=design&t=C9mfwBq2xzhqNpZo-1"
                                target="_blank"
                              ></a>
                              <h2 className="lg:text-3xl leading-4 text-2xl font-semibold lg:leading-5 text-white">
                                Alarm Clock
                              </h2>
                              <div className="flex h-full items-end pb-6">
                                <h3 className="text-lg lg:text-lg leading-5 lg:leading-6 text-white">
                                  A mobile application design for alarm clock.
                                  Wireframes available
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Slide>
                      </div>
                    </Slider>
                  </div>
                  <ButtonNext
                    role="button"
                    aria-label="slide forward"
                    className="w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-pink-100 absolute z-30 right-0 mr-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cutepink"
                    id="next"
                  >
                    <svg
                      width={8}
                      height={14}
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L7 7L1 13"
                        stroke="pink"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ButtonNext>
                </div>
              </CarouselProvider>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" class="xl:h-screen h-auto relative mx-2">
        <Navbar fluid={true} rounded={true} class="bg-transparent ">
          <Navbar.Brand href="#">
            <img src={logo} className="mr-3 md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
        </Navbar>
        <h1 class="mb-4  md:text-6xl text-5xl font-bold tracking-tight leading-none text-cutepink dark:text-cutepink text-center">
          Contact Me
        </h1>

        <div class="grid grid-cols-6 mt-2">
          <div class="col-start-1 col-end-3 justify-self-center ...">
            <a href="https://www.tiktok.com/@melwinkle?_t=8YCFW7N2jcU&_r=1">
              <img src={tiktok} alt="Tiktok" class="md:h-18 h-16"></img>
            </a>{" "}
          </div>
          <div class="col-end-7 col-span-2 justify-self-center ...">
            <a href="mailto:adzo.lisa@gmail.com">
              <img src={email} alt="Email" class="md:h-18 h-16"></img>
            </a>
          </div>
          <div class="col-start-2 col-span-4 justify-self-center ...">
            <a
              href={CV}
              download="Aileen_Akpalu_CV"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={hire} alt="Hire me" class="md:h-24 h-20"></img>
            </a>
          </div>
          <div class="col-start-1 col-span-6 justify-self-center ...">
            {" "}
            <img src={lisa_con} alt="Smiling Bimtoji" class="h-80  "></img>
          </div>
          <div class="col-start-2 col-span-4 justify-self-center ...">
            <a href="+233246055185">
              <img src={phone} alt="Phone" class="md:h-20 h-16"></img>
            </a>
          </div>
          <div class="col-start-1 col-end-3 justify-self-center ...">
            <a href="https://instagram.com/lisa.akpalu?igshid=YTY2NzY3YTc=">
              <img src={instagram} alt="Instagram" class="md:h-20 h-16"></img>
            </a>
          </div>
          <div class="col-end-7 col-span-2 justify-self-center ...">
            <a href="https://www.linkedin.com/in/aileen-akpalu-256b25171">
              <img src={linkedin} alt="LinkedIN" class="md:h-20 h-16"></img>
            </a>
          </div>
        </div>
      </section>
      <script src="../path/to/flowbite/dist/flowbite.js"></script>
    </div>
  );
}

export default App;
